import style from './index.module.scss';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios'
const img2 = '//oss.c-connect.com.cn/img/sqsy-1.png';

export default function Home() {
  const [name,setName] =  useState('');
  const [mobile,setMobile] =  useState('');
  const [email,setEmail] =  useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  console.log('query',location,queryParams.get('type'))
  const submit = ()=>{
    console.log(name,mobile,email)
    if(!name){
      alert('请填写姓名')
      return;
    }
    if(!mobile){
      alert('请填写电话')
      return;
    }
    if(!email){
      alert('请填写电子邮箱')
      return;
    }
    axios({
      url:'//www.c-connect.com.cn/api/apply',
      method:'post',
      headers:{
        'Content-Type':'application/json'
      },
      data:{
        typ:type*1,
        name:name,
        tel:mobile,
        email:email
      }
    }).then(res=>{
      console.log('res');
      if(res.code==0){
        alert('操作成功')
      }else{
        alert('操作失败')
      }
    }).catch((err)=>{

    })
  } 

  return (
    <div className={style.content}>
      <div className={style.area1}>
        <div className={style.cycleImg}>
          <img src={img2} className={style.bgImg} alt="" />
        </div>
        <div className={style.text}>
          <div className={style.box}>
            <div className={style.title}>申请使用</div>
            <div>
              <input className={style.input}   onChange={(e)=>setName(e.target.value)}  placeholder="姓名"/>
            </div>
            <div>
              <input className={style.input}   onChange={(e)=>setMobile(e.target.value)}    placeholder="电话"/>
            </div>
            <div>
              <input className={style.input}   onChange={(e)=>setEmail(e.target.value)}   placeholder="电子邮箱"/>
            </div>
            <div className={style.btn} onClick={submit}>
              提交
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
