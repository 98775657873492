import style from './index.module.scss';




import classNames from 'classnames';
const img2 = '//oss.c-connect.com.cn/img/pingtai11.png';
const img3 = '//oss.c-connect.com.cn/img/pingtai1.png';
const img5 = '//oss.c-connect.com.cn/img/pingtai2.png';
const img6 = '//oss.c-connect.com.cn/img/pingtai3.png';
const img8 = '//oss.c-connect.com.cn/img/pingtai4.png';
const img9 = '//oss.c-connect.com.cn/img/pingtai6.png';
const img10 = '//oss.c-connect.com.cn/img/pingtai5.png';
const img11 = '//oss.c-connect.com.cn/img/pingtai8.png';
const img12 = '//oss.c-connect.com.cn/img/pingtai7.png';
const img13 = '//oss.c-connect.com.cn/img/pingtai9.png';
const img14 = '//oss.c-connect.com.cn/img/pingtai10.png';
const video1 = '//oss.c-connect.com.cn/video/js-1.mp4';
const video2 = '//oss.c-connect.com.cn/video/js-2.mp4';
const video3 = '//oss.c-connect.com.cn/video/js-3.mp4';
const video4 = '//oss.c-connect.com.cn/video/js-4.mp4';

export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <video src={video1} muted className={style.video} autoPlay playsInline loop></video>
        <div className={classNames(style.desc)}  >
          <div className={style.label}>
            <div className={style.title}>平台积分</div>
            <div>
              "平台积分"是在基因智链创客空间中使用的类似加密数字货币，其设计旨在作为交易平台的内部积分，用于购买、交易基因编辑研发成果的所有权、投资基因编辑项目以及进行其他相关交易。

            </div>
          </div>

        </div>
      </div>
      <div className={classNames(style.area1, style.alCenter)}>
        <div className={style.text}>
          <div>
            <p className={style.title}>
              1.类似加密货币属性

            </p>
            <p className={style.label}>
              平台积分是基于区块链技术的加密积分，具有去中心化、安全性高、交易不可逆转等特性。

            </p>
            <p className={style.title}>
              2.交易和投资媒介

            </p>
            <p className={style.label}>
              作为投资和交易的媒介，在基因智链创客空间中用于购买基因编辑研发成果、投资项目、进行交易和交换价值。
            </p>

          </div>
        </div>
        <div className={style.cycleImg}>
          <img src={img2} className={style.bgImg} alt="" />
        </div>
      </div>
      <div className={style.area1}>
        <div className={style.cycleImg}>
          <img src={img3} className={style.bgImg} alt="" />
        </div>
        <div className={classNames(style.text, style.two)}>
          <div className={style.center}>
            <p className={style.title}>
              3.价值体现
            </p>
            <p className={style.label}>
              平台积分的价值与研发成果的阶段、技术成熟度、市场需求等因素相关联。不同阶段的研究成果有不同的平台积分价格。
            </p>
            <p className={style.title}>
              4.智能合约支持
            </p>
            <p className={style.label}>
              交易机制通过智能合约执行，确保交易的透明性、安全性和合法性，为平台积分的交易提供支持。
            </p>
            <p className={style.title}>
              5.投资和交易流动性
            </p>
            <p className={style.label}>
              平台积分兼顾投资者和交易参与者的流动性需求，鼓励更多的参与者进入基因编辑领域的投资和交易。
            </p>
          </div>
        </div>
      </div>


      <div className={style.area}>
        <video src={video2} muted className={style.video} autoPlay playsInline loop></video>
        <div className={classNames(style.desc)}  >
          <div className={style.label}>
            <div className={style.title}>积分作用</div>

          </div>

        </div>
      </div>
      <div className={classNames(style.area2, style.alCenter)}>
        <div className={style.text}>
          <div>
            <p className={style.title}>
              1.投资和交易媒介
            </p>
            <p className={style.label}>
              用于购买、交易基因智链创客空间的研发成果所有权。
              天使投资人、风险投资机构以平台积分形式投资基因智链创客空间。
            </p>
            <p className={style.title}>
              2.智能合约确保权益
            </p>
            <p className={style.label}>
              利用智能合约规定投资条件、权益和回报，确保投资者权益。
              合约执行自动化，根据特定条件(如研发成果完成、市场里程碑等)触发相关交易。
            </p>
            <p className={style.title}>
              3.资产定价与市场化交易
            </p>
            <p className={style.label}>
              基于成果的研发阶段、技术价值等，智能合约帮助定价，形成市场化的交易投资者。
              根据研发成果的不同阶段、市场需求等因素进行交易，实现灵活买卖。
            </p>

          </div>
        </div>
        <div className={style.cycleImg}>
          <div >
            <img src={img5} className={style.centerImg} alt="" />

          </div>
        </div>
      </div>
      <div className={classNames(style.area2, style.alCenter)}>
        <div className={style.cycleImg}>
          <div>
            <img src={img6} className={style.centerImg} alt="" />

          </div>
        </div>
        <div className={classNames(style.text)}>
          <div className={style.center}>
            <p className={style.title}>
              4.风险管理与金融避险
            </p>
            <p className={style.label}>
              投资者根据风险承受能力选择不同阶段的投资，分散风险，实现风险管理。
              基于智能合约的衍生品交易平台，为投资者提供金融避险工具。
            </p>
            <p className={style.title}>
              5.投后管理与市值管理
            </p>
            <p className={style.label}>
              区块链上的数据追溯和透明度，有助于投后管理，监督资金使用和成果达成。
              针对投资组合进行实时市值管理，评估投资的实际价值和回报。
            </p>
            <p className={style.title}>
              6.上市和流动性
            </p>
            <p className={style.label}>
              研发成果可根据市场发展情况选择上市。
              区块链提供高流动性的市场，方便持有者在需要时出售或交易。
            </p>
          </div>
        </div>
      </div>


      <div className={style.area}>
        <video src={video3} muted className={style.video} autoPlay playsInline loop></video>
        <div className={classNames(style.desc)}  >
          <div className={style.label}>
            <div className={style.title}>成果分配机制</div>
          </div>
        </div>
      </div>

      <div className={classNames(style.area2, style.alCenter)}>
        <div className={style.text}>
          <div>
            <p className={style.title}>
              1.成果认定与平台积分分配
            </p>
            <p className={style.label}>
              独立的第三方专家组对各研发团队的成果进行评估和认定。除了评估成果的技术难度、应用价值、市场前景等因素外，研发团队在实验室活动中的贡献程度、持续创新能力和市场转化潜力等因素也纳入评估体系。
              根据成果认定的结果，将各研发团队的成果划分为不同的等级，每个等级对应不同的分值。同时，根据各团队在实验室活动中的贡献程度和持续创新能力等因素，可以为每个团队分配不同数量的初始平台积分。
            </p>
          </div>
        </div>
        <div className={style.cycleImg}>
          <div >
            <img src={img8} className={style.centerImg} alt="" />
          </div>
        </div>
      </div>
      <div className={classNames(style.area2, style.alCenter)}>
        <div className={style.cycleImg}>
          <div>
            <img src={img9} className={style.centerImg} alt="" />
          </div>
        </div>
        <div className={classNames(style.text)}>
          <div className={style.center}>
            <p className={style.title}>
              2.交易模块中的平台积分使用
            </p>
            <p className={style.label}>
              各研发团队通过提交成果交易申请，将成果挂牌出售或寻求合作伙伴。挂牌的成果将根据其质量和市场需求进行定价。当研发团队与投资人达成交易意向后，双方通过智能合约进行在线交易。
              在交易过程中，平台积分作为衡量成果价值和交易金额的媒介。交易金额按照成果等级和市场需求进行定价，而平台积分则用于支付交易金额和奖励给研发团队。
              为了确保交易的公平性和透明性，使用智能合约将交易过程进行记录和公示。智能合约可以确保交易双方无法进行欺诈行为或恶意操作，同时也可以让其他研发团队和投资人更好地了解交易详情和监督交易过程。
            </p>
          </div>
        </div>
      </div>
      <div className={classNames(style.area2, style.alCenter)}>
        <div className={style.text}>
          <div>


            <p className={style.title}>
              3.孵化模块中的平台积分使用
            </p>
            <p className={style.label}>
              实验室为有潜力的基因编辑各类主题项目提供资金、技术支持等资源，以平台积分的形式进行发放和结算。
              对于获得孵化的项目，为其分配更多的平台积分作为支持。这些平台积分用于购买实验室的仪器设备、材料试剂等必要的研发资源，也可用于支付项目成员的劳动报酬和研究费用等。
              同时，实验室组织专家对项目进行定期评估和监督，以确保项目的顺利推进和成果的质量。根据项目的评估结果和进展情况，实验室调整对项目的支持力度和分配的平台积分数量。
            </p>
          </div>
        </div>
        <div className={style.cycleImg}>
          <div >
            <img src={img10} className={style.centerImg} alt="" />
          </div>
        </div>
      </div>
      <div className={classNames(style.area2, style.alCenter)}>
        <div className={style.cycleImg}>
          <div>
            <img src={img11} className={style.centerImg} alt="" />
          </div>
        </div>

        <div className={classNames(style.text)}>
          <div className={style.center}>
            <p className={style.title}>
              4.激励机制与平台积分持有量
            </p>
            <p className={style.label}>

              根据团队成员在项目中的贡献程度、研究成果的质量和数量等因素，为团队成员分配不同数量的平台积分。设立一些额外的奖励机制，最佳研究成果奖、最佳创新团队奖等，以鼓励团队成员积极创新和追求卓越。
              持有更多平台积分的团队将有更多的机会参与高端项目和优质资源，形成正向的激励循环。此外，平台积分也作为团队成员的个人奖励，根据成员在团队中的贡献程度进行分配。这将激励团队成员更加积极地参与研发活动，提高整体团队的研发效率和创新能力。
            </p>
          </div>
        </div>
      </div>
      <div className={classNames(style.area2, style.alCenter)}>
        <div className={style.text}>
          <div>



            <p className={style.title}>
              5.管理机制与监督执行
            </p>
            <p className={style.label}>
              管理机构将由多方参与组成，包括实验室管理委员会、投资人代表、研发团队代表等。管理机构将负责制定、监督、执行平台积分分配的相关政策和规定，确保交易过程的公正性和合规性。同时，管理机构还将对交易数据进行监督和审核，防止任何形式的欺诈行为和不公正交易。
            </p>
          </div>
        </div>
        <div className={style.cycleImg}>
          <div >
            <img src={img12} className={style.centerImg} alt="" />
          </div>
        </div>
      </div>
      <div className={style.area}>
        <video src={video4} muted className={style.video} autoPlay playsInline loop></video>
        <div className={classNames(style.desc)}  >
          <div className={style.label}>
            <div className={style.title}>交易机制</div>
          </div>
        </div>
      </div>
      <div className={style.area1}>

        <div className={classNames(style.text, style.two, style.color)}>
          <div className={style.center}>
            <p className={style.title}>

              1.平台积分作为投资和交易媒介
            </p>
            <p className={style.label}>

              用于购买、交易基因智链创客空间的研发成果所有权。
              天使投资人、风险投资机构以平台积分形式投资基因智链创客空间。

            </p>
            <p className={style.title}>
              2.智能合约确保权益
            </p>
            <p className={style.label}>

              利用智能合约规定投资条件、权益和回报，确保投资者权益。
              合约执行自动化，根据特定条件(如研发成果完成、市场里程碑等)触发相关交易。
            </p>
            <p className={style.title}>
              3.资产定价与市场化交易
            </p>
            <p className={style.label}>


              基于成果的研发阶段、技术价值等，智能合约帮助定价，形成市场化的交易投资者。
              根据研发成果的不同阶段、市场需求等因素进行交易，实现灵活买卖。
            </p>
          </div>
        </div>
        <div className={style.cycleImg}>
          <img src={img13} className={style.bgImg} alt="" />
        </div>
      </div>

      <div className={classNames(style.area1, style.alCenter)}>
        <div className={style.cycleImg}>
          <img src={img14} className={style.bgImg} alt="" />
        </div>
        <div className={style.text}>
          <div>
            <p className={style.title}>
              4.风险管理与金融避险
            </p>
            <p className={style.label}>
              投资者根据风险承受能力选择不同阶段的投资，分散风险，实现风险管理。
              基于智能合约的衍生品交易平台，为投资者提供金融避险工具。
            </p>
            <p className={style.title}>
              5.投后管理与市值管理
            </p>
            <p className={style.label}>
              区块链上的数据追溯和透明度，有助于投后管理，监督资金使用和成果达成。
              针对投资组合进行实时市值管理，评估投资的实际价值和回报。
            </p>
            <p className={style.title}>
              6.上市和流动性
            </p>
            <p className={style.label}>
              研发成果可根据市场发展情况选择上市。
              区块链提供高流动性的市场，方便持有者在需要时出售或交易。
            </p>

          </div>
        </div>

      </div>



    </div>
  );
}
