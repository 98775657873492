import style from './index.module.scss';


import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { Link } from 'react-router-dom';

import 'swiper/css/pagination';
import 'swiper/scss';
// const video1 = '//oss.c-connect.com.cn/video/jsbj.mp4';
const imgtop = '//oss.c-connect.com.cn/img/home-top.png';
const img1 = '//oss.c-connect.com.cn/img/gywm-2.png';
const img2 = '//oss.c-connect.com.cn/img/js-bannner-1.png';

const img3 = '//oss.c-connect.com.cn/img/js-bannner-2.png';
const img4 = '//oss.c-connect.com.cn/img/js-bannner-3.png';
const img5 = '//oss.c-connect.com.cn/img/gywm-5.png';

const img22 = '//oss.c-connect.com.cn/img/shouye-平台积分@2x.png';

const img11 = '//oss.c-connect.com.cn/img/shouye-行动者@2x.png';
const img33 = '//oss.c-connect.com.cn/img/shouye-自组织机制@2x.png';

export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
      {/* <video src={video1} muted className={style.video} autoPlay playsInline loop></video> */}
        <img src={imgtop} className={style.bgImg} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={style.title}>
            <div >云码（上海）基因技术有限公司</div>
          </div>
          <div className={style.tip}>
         一个专注于基因编辑研究领域的创客空间，致力于推动基因编辑各类主题的研发、交易和知识产权保护于一体的自组织机制网络生态系统。
          </div>
        </div>

      </div>
      <div className={style.info}>
      公司于2024甲子年在上海成立，人类基因组测序计划成果公布20年来，在国内经历了和互联网发展几乎同样的时间，但基因科技真正普惠应用还是近5年。云码（上海）基因技术有限公司应运而生，致力于加强产业链上下游以及产学研用的合作，共同推动生命健康产业的可持续发展，<span className={style.blue}>将技术产品化，产品产业化，产业生态化。</span>

      </div>
      <div className={style.swWrap}>
        <div className={style.container}>
          <Swiper
            modules={[Pagination, Autoplay]}
            slidesPerView={2}
            spaceBetween={30}
            centeredSlides={true}
            autoplay
            loop
            pagination={{
              clickable: true,
          
            }}
          >
            <SwiperSlide className={style.swiperSlide}>
              <div className={style.item}>
                <img
                  src={img2}
                  alt=''
                />
               
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div  className={style.item}>
                <img
                        src={img3}
                  alt=''
                />
             
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div  className={style.item}>
                <img
                        src={img4}
                  alt=''
                />
             
              </div>
            </SwiperSlide>
          </Swiper>
         
        </div>
      </div>
      <div className={style.area1}>
        <img src={img11} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc1)}  >
          <div>
          <Link className={style.item} to='/xdz'>
            <div className={style.title}>行动者</div>
            <div className={style.more}>
           
              进一步了解 >
         
            </div>
            </Link>
          </div>

        </div>

      </div>

      <div className={style.area1} >
        <img src={img22} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc2)}  >
          <div>
          <Link className={style.item} to='/ptjf'>
            <div className={style.title}>平台积分</div>
            <div className={style.more}>
      
            进一步了解 >
          
            </div>
            </Link>
          </div>

        </div>
      </div>
      <div className={style.area1} >
        <img src={img33} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc1)}>
          <div>
          <Link className={style.item} to='/zzz'>
            <div className={style.title}>自组织机制</div>
            <div className={style.more}>
       
            进一步了解 >
         
            </div>
            </Link>
          </div>

        </div>
      </div>

      <div className={style.area}>
        <img src={img5} className={classNames(style.bgImg,style.mt2)} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={style.title2}>
            <div >公司愿景</div>
          </div>
          <div className={style.tip2}>
          互联网+基因技术仍然是一个起步门槛较高的蓝海领域，它的未来充满了巨大的想象空间。但是，基因产品设计的不成熟，产业链信息割裂严重，投资回报周期长等问题还有待于基因行业自身的发展来进一步夯实。因此，云码（上海）基因技术有限公司希望成为基因编辑行业的布道者和先行者，推动生命科技普惠和产业创新发展。
          </div>
        </div>

      </div>

    
    </div>
  );
}
