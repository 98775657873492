import style from './index.module.scss';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useMatches, NavLink,  useNavigate,useLocation } from 'react-router-dom';
const logo = '//oss.c-connect.com.cn/img/logo.png';

const data = [
  [
   
    {
      title: '行动者',
      items: [
        {
          title: '行动者', 
          path:'/xdz',
        },
        {
          title: '行动者群体',
          path:'/xdz',
        },
        {
          title:'行动者使命',
          path:'/xdz',
        },
        {
          title: '行动者宗旨',
          path:'/xdz',
        }
      
      ],
    },
  
  ],
  [
    {
      title: '平台积分',
      items: [
        {
          title: '平台积分',
          path:'/ptjf',
        },
        {
          title: '积分作用',
          path:'/ptjf',
        },
        {
          title:'分配机制',
          path:'/ptjf',
        },
        {
          title: '交易机制',
          path:'/ptjf',
        }
      
      ],

    },
  
  ],
  [
    {
      title: '自组织机制',

      items: [
        {
          title: '开发包容',
          path:'/zzz',
        },
        {
          title: '公平公正',
          path:'/zzz',
        },
        {
          title:'激励创新',
          path:'/zzz',
        },
        {
          title: '协调发展',
          path:'/zzz',
        },
        {
          title:'动态调整',
          path:'/zzz',
        },
      
      ],

    },
  
  ],
  [
    {
      title: '核心功能',
      items: [
        {
          title: '基因智链创客空间',
          path:'/hxgn',
        },
        {
          title: 'AI基因编辑模拟实验室',
          path:'/hxgn',
        },
       
      
      ],
    },
  
  ],
  [
    {
      title: '功能服务',
      items: [
        {
          title: '服务领域',
          path:'/gnfw',
        },
        {
          title: '服务内容',
          path:'/gnfw',
        },
        {
          title:'服务对象',
          path:'/gnfw',
        },
    
      
      ],

    },
  
  ],

  [
    {
      title: '技术背景',
      items: [
        {
          title: '技术介绍',
          path:'/jsbj',
        },
        // {
        //   title: '关于我们',
        //   path:'/gywm',
        // },
  
      ],
    },
  
  ],
   // {
    //   title: '首页',
    //   path:'/gywm',
    //   item:[],
   
    // },

];

export default function Header() {
  const [cur, setCur] = useState(-1);
  const [hoverNum, setHover] = useState(-1);
  const [curPage,setCurPage] = useState(-1);
  useEffect(()=>{

    if(location.pathname==='/xdz'){
      setCurPage(0)

    }else if(location.pathname==='/ptjf'){
      setCurPage(1)
    }else if(location.pathname==='/zzz'){
      setCurPage(2) 
    }else if(location.pathname==='/hxgn'){
      setCurPage(3)
    }else if(location.pathname==='/gnfw'){
      setCurPage(4)
    }else if(location.pathname==='/jsbj'){
      setCurPage(5)
    }else if(location.pathname==='/'){
      setCurPage(6)
    }
   
  })
  const location = useLocation();

  const pre = useRef();
  function hide() {
    console.log('aa===')
    const timer = setTimeout(cur => {
      setCur(-1);
    }, 50);
    pre.current = timer;
  }
  function show(num) {
    console.log('-----')
    if (pre.current) {
      clearTimeout(pre.current);
    }
    setCur(num);
    hover(num);
    
  }

  function hover(num){
    setHover(num)
  }
  let matches = useMatches();
  let customStyle = matches
    .filter(match => Boolean(match.handle?.style))
    .map(i => i.handle.style);
  const navigate = useNavigate();

  function handleClick(i) {
    navigate(i.path||'home');
    hide();
  }

  return (
    <div
      className={classNames(style.header, {
        [style.active]: cur > -1,
      })}
      onMouseLeave={hide}
      style={{ background: customStyle.length ? customStyle[0].headerBg : '' }}
   >
      <div
        className={style.logo}
        style={{ color: customStyle.length ? customStyle[0].headerColor : '' }}>
        <NavLink to='/'>
          <img src={logo} alt="" />
        </NavLink>
      </div>
      <div
        className={style.nav}
        style={{ color: customStyle.length ? customStyle[0].headerColor : '' }}>
            <div
          className={classNames(style.navItem, { [style.on]: cur === 6||hover==6 },{ [style.on]: curPage === 6 })}
          sort='1'
          onClick={() => {
            navigate('/');
          }}
          onMouseOver={() => {
            show(6);
          }}
          >
          首页
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 5 },{ [style.on]: curPage === 5 })}
          onClick={() => {
            navigate('/jsbj');
          }}
          onMouseOver={() => {
            show(5);
          }}>
          技术介绍
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 0 },{ [style.on]: curPage === 0 })}
          sort='1'
          onMouseOver={() => {
            show(0);
          }}>
          行动者
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 1 },{ [style.on]: curPage === 1 })}
          sort='1'
          onMouseOver={() => {
            show(1);
          }}>
          平台积分
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 2 },{ [style.on]: curPage === 2 })}
          onMouseOver={() => {
            show(2);
          }}>
          组织机制
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 3 },{ [style.on]: curPage === 3 })}
          onMouseOver={() => {
            show(3);
          }}>
          核心功能
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 4 },{ [style.on]: curPage === 4 })}
          onMouseOver={() => {
            show(4);
          }}>
          功能服务
        </div>
      
      </div>
      {
        (cur > -1&&cur!=6&&cur!=5)&&(
          <div className={style.wrap}   >
          <div className={style.lists}>
            { 
              data[cur]&&data[cur].map((item,index) => {
                return (
                  <div className={style.group} key={item.title}>
                    {item.title && (
                      <div
                      className={style.title}>{item.title}
                       {item.items&&item.items.map(i => {
                          return (
                            <span className={style.item} key={i.title || i} onClick={() => handleClick(i)}>
                              <span>{i.title || i}</span>
                            </span>
                          );
                        })}
                      </div>
                    )}
                    {item.img && <img src={item.img} className={style.img} />}
                    <div
                      className={classNames(style.products, {
                        [style.center]: item.img,
                      })}
                     >
                      {/* {item.items&&item.items.map(i => {
                        return (
                          <span className={style.i} key={i.title || i} onClick={() => handleClick(i)}>
                            <span>{i.title || i}</span>
                          </span>
                        );
                      })} */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        )
      }
 
    </div>
  );
}
