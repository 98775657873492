import style from './index.module.scss';
import classNames from 'classnames';

const img1 = '//oss.c-connect.com.cn/img/行动者@2x.png';
const img2 = '//oss.c-connect.com.cn/img/行动者群体@2x.png';
const img3 = '//oss.c-connect.com.cn/img/行动者使命@2x.png';
const img4 = '//oss.c-connect.com.cn/img/行动者宗旨@2x.png';
export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc1)}  >
          <div>
            <div className={style.title}>行动者</div>
            <div className={style.info1}>
              在基因编辑智链仿真模拟实验室中扮演关键角色的研究领导者、项目经理或者团队领导者。承担着项目的决策者和执行者职责，促进团队成员间的协作和沟通，推动科学创新与知识共享。作为自组织机制的关键行动者，助力于构建一个具有自创生、自生长、自适应、自复制、自修复、自我完善特性的基因编辑研发生态系统。
            </div>
          </div>

        </div>

      </div>

      <div className={style.area}>
        <img src={img2} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc2)}  >
          <div>
            <div className={style.title}>行动者</div>
            <div className={style.info2}>
              在基因编辑智链仿真模拟实验室中扮演关键角色的研究领导者、项目经理或者团队领导者。承担着项目的决策者和执行者职责，促进团队成员间的协作和沟通，推动科学创新与知识共享。作为自组织机制的关键行动者，助力于构建一个具有自创生、自生长、自适应、自复制、自修复、自我完善特性的基因编辑研发生态系统。
            </div>
          </div>

        </div>

      </div>


      <div className={style.area} >
        <img src={img3} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc3)}  >
          <div>
            <div className={style.title}>行动者使命</div>
            <div className={style.info3}>
              <div>
                <div className={style.pItem}>
                  <p>
                    1.项目引领与发起：

                  </p>
                  <div>
                    负责发起并引领基因编辑各类主题研究项目。
                    指导项目方向、明确目标，并促进团队成员的共识与合作。
                  </div>

                </div>

                <div className={style.pItem}>
                  <p>
                    2.资源整合与分配：

                  </p>
                  <div>
                    协调各类资源，包括技术资料、数据、人才等。
                    负责合理分配研发工具、设备和资金，以支持项目的顺利进行。
                  </div>

                </div>

                <div className={style.pItem}>
                  <p>
                    3.社区建设与沟通：


                  </p>
                  <div>
                    扮演社区引领者的角色，促进成员之间的交流与合作。
                    知识分享、问题讨论，维系社区氛围，加强团队凝聚力。
                  </div>

                </div>



              </div>
              <div>

                <div className={style.pItem}>
                  <p>
                    4.决策协调与项目推 进：


                  </p>
                  <div>
                    负责推动项目进展，协调决策，解决团队中的矛盾与问题。
                    推进项目执行、监督进度，并保证项目方向与目标一致。
                  </div>

                </div>
                <div className={style.pItem}>
                  <p>
                    5.激励与奖励激励：


                  </p>
                  <div>
                    通过激励机制，激发团队成员的创新和贡献。
                    评估成果，给予相应奖励以激励更多成员积极参与。
                  </div>

                </div>

                <div className={style.pItem}>
                  <p>
                    6.机制优化与自我调整：

                  </p>
                  <div>
                    不断优化自组织机制，以适应项目发展的需求。
                    根据反馈和经验，对机制进行调整和改进，确保生态系统的稳健运行。
                  </div>

                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.area} >
        <img src={img4} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc4)}>
          <div>
            <div className={style.title}>行动者宗旨</div>
            <div className={style.info4}>
              <div>
                <div className={style.item}>
                  <span className={style.label}>1.创新性和自主发展：</span>
                  行动者通过创新性思维和自主发展能力，探索和拓展基因编辑研发的各个主题领域。

                </div>
                <div className={style.item}>
                <span className={style.label}>2.生态系统的复杂互动：</span>
                 这个系统由许多相互关联、互相影响的基因编辑研究主题组成，它们之间相互交叉、相互影响，形成一个复杂的、多样化的生态系统。
                </div>
                <div className={style.item}>
                <span className={style.label}>3.发展路径的记录和指导：</span>
                 进化树系统以图谱形式记录不同主题的发展历程。

                </div>
                <div className={style.item}>
                <span className={style.label}>4.自我修复和演化：</span>
                 自组织机制具有一定的自我修复和演化能力，能够在面对挑战或变化时做出相应的调整和适应。

                </div>
                <div className={style.item}>
                <span className={style.label}>5.自我完善和自适应性：</span>
               根据研究者的参与和贡献系统不断进化和发展，提高自身的适应性和创新性。

                </div>
                <div className={style.item}>
                <span className={style.label}>6.整合协作和发展：</span>
                  行动者之间能够进行合作、交流，共同推动基因编辑领域的发展，并且这种发展是由他们自主完成的。
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
}
