import style from './index.module.scss';

import classNames from 'classnames';
import {useEffect} from 'react'
const img1 = '//oss.c-connect.com.cn/img/lxwm.png';

export default function Home() {
  useEffect(()=>{
    console.log('aa--')
    var map = new window.BMapGL.Map("map");          // 创建地图实例 
  var point = new window.BMapGL.Point(121.488266,30.956533);  // 创建点坐标 
  map.centerAndZoom(point, 15);     
  })
  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc1)}  >
            <div className={style.title}>联系我们</div>
        </div>
      </div>
      <div className={style.info}>
        <p>
        联系电话：15257168741
        </p>
        <p>
        公司邮箱：yunjiema@hzyjm.cn
        </p>
        <p>
        公司地址：上海奉贤区金海公路6055号11幢5层
        </p>
      </div>
      <div className={style.map} id="map">
      </div>
    </div>
  );
}
