import style from './index.module.scss';
const img1 = '//oss.c-connect.com.cn/img/yingyezhizhao.png';

export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
 

      </div>



    </div>
  );
}
