import style from './index.module.scss';
import classNames from 'classnames';
import {  useNavigate } from 'react-router-dom';
const img1 = '//oss.c-connect.com.cn/img/hxgn-a-1@2x.png';
export default function Home() {
const navigate = useNavigate();

  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={style.center}>
            <div className={style.title}>
              功能服务
            </div>
            <div>
              <div>
                <span className={style.c}> 服务领域:</span>专注于基因编辑领域。
              </div>
              <div className={style.l}> 
              <span className={classNames(style.c)} >
                服务内容:
              </span>
              为基因编辑行业的创业者及关注者提供一站式项目孵化的服务，为基因编辑领域的拉资人提供链接优质项目的渠道，为基因编辑产业链上的各类企业提供客户合作渠道。

              </div>
          
            </div>

          </div>
        </div>
      </div>
      <div className={style.area}>
        <div className={style.topTitle}>
        我们的服务对象
        </div>
        
        <div className={classNames(style.desc1)}  >
          <div className={style.item}>
            <div className={style.name}>基因编辑行业创业者</div>
            <div className={style.info}>  
              <p>寻找创始成员 </p>
              <p>组建创始团队 </p>
              <p>设计激励模型AI基因实验室 </p>
              <p>寻找孵化器 </p>
              <p>寻找产业链服务企业 </p>
            </div>
            <div  onClick={()=>{navigate('/hxgn-sqsy?type=1')}} className={style.btn}> 
              免费使用
            </div>
          </div>
          <div  className={classNames(style.item,style.v2)}>
            <div className={style.name}>基因编辑领域关注者</div>




            <div  className={style.info}> 
              <p>技术赋能基因编辑 </p>
              <p>寻求跨领域合作 </p>
              <p>探索基因编辑技术的跨界应用 </p>
          
            </div>
            <div  onClick={()=>{navigate('/hxgn-sqsy?type=2')}}  className={style.btn}>  
              免费使用
            </div>
          </div>
          <div  className={classNames(style.item,style.v3)}>
            <div className={style.name}>基因编辑领域投资人</div>
            <div  className={style.info}> 
              <p>寻追踪行业热点动态 </p>
              <p>找合适投资项目 </p>
              <p>项目孵化咨询 </p>
              <p>寻找战略合作伙伴 </p>
            </div>
            <div onClick={()=>{navigate('/hxgn-sqsy?type=3')}}  className={style.btn}> 
              免费使用
            </div>
          </div>
          <div  className={classNames(style.item,style.v4)}>
            <div className={style.name}>基因编辑产业服务企业</div>
            <div  className={style.info}> 
              <p>基因实验室 </p>
              <p>基因数据公司 </p>
              <p>专利咨询公司 </p>
              <p>法律咨询公司 </p>
            </div>
            <div onClick={()=>{navigate('/hxgn-sqsy?type=4')}}   className={style.btn}> 
              免费使用
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
